<template>
    <!-- DELIVERY ZONES TAB -->
    <v-tab-item
        key="delivery_zones_tab"
        :transition="false"
        :reverse-transition="false"
        eager
    >
        <!-- RESTAURANT ADDRESS -->
        <form-panel :title="$t('labels.restaurant_address')">
            <form-two-col-row>
                <template v-slot:col1>
                    <address-autocomplete-input
                        v-model="model.location"
                        v-validate.immediate="'required'"
                        v-tab-error:deliveryZones
                        label="labels.address"
                        name="location"
                        :error-messages="errors.collect('location')"
                        :data-vv-as="$t('labels.address')"
                        :town.sync="model.city"
                        :longitude.sync="model.longitude"
                        :latitude.sync="model.latitude"
                    ></address-autocomplete-input>
                </template>
                <template v-slot:col2>
                    <address-map-input
                        v-tab-error:deliveryZones
                        style="height: 300px"
                        :longitude.sync="longitude"
                        :latitude.sync="latitude"
                        name="location"
                    ></address-map-input>
                    <v-row>
                        <v-col cols="6">
                            <text-input
                                v-model="inputLatitude"
                                v-validate.immediate="'required|decimal:6'"
                                v-tab-error:deliveryZones
                                name="restaurant.latitude"
                                label="labels.latitude"
                                :error-messages="
                                    errors.collect('restaurant.latitude')
                                "
                                :data-vv-as="$t('labels.latitude')"
                            ></text-input>
                        </v-col>
                        <v-col cols="6">
                            <text-input
                                v-model="inputLongitude"
                                v-validate.immediate="'required|decimal:6'"
                                v-tab-error:deliveryZones
                                name="restaurant.longitude"
                                label="labels.longitude"
                                :error-messages="
                                    errors.collect('restaurant.longitude')
                                "
                                :data-vv-as="$t('labels.longitude')"
                            ></text-input>
                        </v-col>
                    </v-row>
                </template>
            </form-two-col-row>
        </form-panel>

        <!-- DELIVERY AREA -->
        <form-panel :title="$t('labels.delivery_area')">
            <form-two-col-row>
                <template v-slot:col1>
                    <entity-select-input
                        v-model="model.deliveryAreaType"
                        v-validate.immediate="''"
                        v-tab-error:deliveryZones
                        label="labels.delivery_area_type"
                        :data-vv-as="$t('labels.delivery_area_type')"
                        :entity="
                            selectApiClientType.RESTAURANT_DELIVERY_AREA_TYPE
                        "
                        name="deliveryAreaType"
                        autocomplete
                        :error-messages="errors.collect('deliveryAreaType')"
                        translate-items
                    ></entity-select-input>
                </template>
            </form-two-col-row>
        </form-panel>

        <form-panel :title="$t('labels.delivery_areas')">
            <div class="row">
                <div class="col-md-9">
                    <accordion
                        v-if="model.variantAreas.length"
                        :panel="activeAccordionPanel"
                    >
                        <delivery-area
                            v-for="(area, index) in model.variantAreas"
                            :key="area['@id']"
                            :name="area.name"
                            :value="area"
                            :model-object="model"
                            @input="setArea($event, index)"
                            @remove-area="removeArea(index)"
                            @tabError="tabErrorsContainer = $event"
                        ></delivery-area>
                    </accordion>
                </div>
                <div class="col-md-3">
                    <v-btn
                        class="ma-2"
                        outlined
                        color="success"
                        @click="addArea"
                    >
                        <v-icon left>add</v-icon> {{ $t('actions.add') }}
                    </v-btn>
                </div>
            </div>
        </form-panel>
    </v-tab-item>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'
import SelectApiClientType from '@/api/SelectApiClientType'

import FormPanel from '@/components/form/FormPanel'
import FormTwoColRow from '@/components/form/FormTwoColRow'
import EntitySelectInput from '@/components/form/Inputs/EntitySelectInput'
import AddressAutocompleteInput from '@/components/form/Inputs/google/AddressAutocompleteInput'
import AddressMapInput from '@/components/form/Inputs/google/AddressMapInput'
import TextInput from '@/components/form/Inputs/TextInput'

import Accordion from '@/components/elements/Accordion'
import DeliveryArea from './areas/DeliveryArea'

import FormTabMixin from '@/components/mixins/FormTabMixin'
import FormInput from '@/components/mixins/FormInput'

const areaModel = {
    '@type': 'RestaurantArea',
    area: [],
    deliveryDelay: 0,
    deliveryPriceLimit: 0,
    deliveryRadius: null,
    isActive: true,
    name: null,
    variants: [],
}

export default {
    components: {
        FormPanel,
        FormTwoColRow,
        EntitySelectInput,
        AddressAutocompleteInput,
        AddressMapInput,
        TextInput,
        DeliveryArea,
        Accordion,
    },

    mixins: [FormTabMixin, FormInput],

    inject: {
        $validator: 'formValidator',
    },

    data() {
        return {
            selectApiClientType: SelectApiClientType,
            activeAccordionPanel: null,
        }
    },

    computed: {
        latitude: {
            get() {
                return this.model?.latitude
            },
            set(val) {
                this.setFloat('latitude', val)
            },
        },
        longitude: {
            get() {
                return this.model?.longitude
            },
            set(val) {
                this.setFloat('longitude', val)
            },
        },
        inputLatitude: {
            get() {
                return this.model?.latitude
            },
            set(val) {
                this.setInputFloat('latitude', val)
            },
        },
        inputLongitude: {
            get() {
                return this.model?.longitude
            },
            set(val) {
                this.setInputFloat('longitude', val)
            },
        },
    },

    methods: {
        setArea(area, index) {
            if (area && area['@type'])
                this.$set(this.model.variantAreas, index, area)
        },

        removeArea(index) {
            this.$delete(this.model.variantAreas, index)
            this.$set(this, 'activeAccordionPanel', null)
        },

        addArea() {
            if (
                this.model.variantAreas &&
                Array.isArray(this.model.variantAreas)
            ) {
                this.$set(this.model, 'variantAreas', [
                    ...this.model.variantAreas,
                    cloneDeep(areaModel),
                ])
            } else {
                this.$set(this.model, 'variantAreas', [cloneDeep(areaModel)])
            }
            this.$set(
                this,
                'activeAccordionPanel',
                this.model.variantAreas.length - 1
            )
        },

        setFloat(field, value) {
            this.$set(
                this.model,
                field,
                value === '' || value === null
                    ? null
                    : parseFloat(parseFloat(value).toFixed(6))
            )
        },

        setInputFloat(field, value) {
            this.$set(
                this.model,
                field,
                value === '' || value === null ? null : parseFloat(value)
            )
        },
    },
}
</script>
